/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/pretty-checkbox@3.0.3/dist/pretty-checkbox.min.css
 * - /npm/croppie@2.6.4/croppie.min.css
 * - /npm/select2@4.0.13/dist/css/select2.min.css
 * - /npm/@chenfengyuan/datepicker@1.0.9/dist/datepicker.min.css
 * - /npm/@fancyapps/fancybox@3.5.7/dist/jquery.fancybox.min.css
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
